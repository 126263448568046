import React from 'react';
import PropTypes from 'prop-types';

import PortfolioListEntry from '../components/portfolio-list-entry';

const PortfolioListingComponent = ({projects, types}) => (
    <div className="portfolio-listing-component">
        <div className="component-wrapper">
            <ul className="projects-list">
                {projects.map(({node}) => (
                    <PortfolioListEntry
                        post={node}
                        types={types}
                        key={node.slug}
                    />
                ))}
            </ul>
        </div>
    </div>
);

PortfolioListingComponent.propTypes = {
    projects: PropTypes.array.isRequired,
    types:    PropTypes.object.isRequired
};

export default PortfolioListingComponent;
