import React from "react";
import { graphql } from "gatsby";
import get from 'lodash.get';

import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import WorkFilters from '../components/work-filters';
import PortfolioListingComponent from '../components/portfolio-listing-component';

const TypePage = ({data}) => {

    const projects            = get(data, 'allWordpressWpPortfolio.edges', []);
    const projectTypes        = get(data, 'allWordpressWpProjectTypes.edges', []);
    const {name, description} = get(data, 'wordpressWpProjectTypes', {name: '', description: ''});

    const siteTitle = get(data, 'site.siteMetadata.title', '');

    const allProjectTypes = projectTypes.reduce((acc, {node: curr}) => {
        acc[curr.wordpress_id] = curr.name;
        return acc;
    }, {});

    return (
        <Layout>
            <PageHeader
                heading={name}
                subHeading={description}
                pageTitle={`${name} Archives | ${siteTitle}`}
            />
            <WorkFilters projectTypes={allProjectTypes} />
            <PortfolioListingComponent
                types={allProjectTypes}
                projects={projects}
            />
        </Layout>
    );
};

export default TypePage;

export const pageQuery = graphql`
    query TypesQuery($wordpress_id: Int!) {
        site {
            siteMetadata {
                title
            }
        }
        allWordpressWpPortfolio(filter: {project_types: {eq: $wordpress_id}}) {
            edges {
                node {
                    project_types
                    title
                    id
                    slug
                    acf {
                        project_subheading
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 605){
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        allWordpressWpProjectTypes {
            edges {
                node {
                    name
                    wordpress_id
                }
            }
        }
        wordpressWpProjectTypes(wordpress_id: {eq: $wordpress_id}) {
            description
            name
        }
    }
`;

