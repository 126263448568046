import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const WorkFilters = ({projectTypes}) => (
    <div className="work-filters-component">
        <ul>
            <li>
                <Link
                    to="/portfolio/"
                    activeClassName="active"
                >all</Link>
            </li>
            {Object.entries(projectTypes).map((type) => (
                <li key={type[0]}>
                    <Link
                        to={`/portfolio/type/${type[1].toLowerCase()}/`}
                        activeClassName="active"
                    >{type[1]}</Link>
                </li>
            ))}
        </ul>
    </div>
);

WorkFilters.propTypes = {
    projectTypes: PropTypes.object.isRequired,
};

export default WorkFilters;
